<template>
  <a-modal title="生成二维码" v-model="visible" cancelText="取消" okText="下载" width="650px"
    @ok="handleOk" @cancel="handleCancel" class="survey-qrcode">
        <div :style="{height:wHeight}" class="hdgl-qrcodes">
            <img :src="imgurl" alt="" class="share-image">
            <!-- <vue-canvas-poster :widthPixels="750" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster> -->
            <vue-canvas-poster class="poster" :widthPixels="750" :painting="painting" @success="success" @fail="fail"></vue-canvas-poster>
        </div>
  </a-modal>
</template>

<script>
    import { VueCanvasPoster } from 'vue-canvas-poster'
    export default {
        name:'qrcodeModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                painting: {
                    width: '550px',
                    height: '676px',
                    background: '#FFF',
                    views: [
                        {
                            type: 'rect',
                            css: {
                            top: '20px',
                            left: '12px',
                            // color: '#fff',
                            width: '526px',
                            height: '340px',
                            borderRadius: '10px',
                            color: '#f4f5f7',
                            }
                        },
                        {
                            type: 'image',
                            url: this.item.survey_image,
                            // url:'https://i01.wedots.cn/2020/12/17/03ea71ab5385b48811d3b766de11415c.png',
                            css: {
                                top: '32.5px',
                                left: '25px',
                                width: '500px',
                                height: '262px',
                                borderRadius: '10px',
                            }
                        },
                        {
                            type: 'text',
                            text: this.item.survey_name,
                            css:[
                                {
                                    width: '500px',
                                    top:'310px',
                                    left:'30px',
                                    maxLines:1,
                                    // textAlign:'center',
                                    fontSize:'20px',
                                    lineHeight:'24px'
                                }
                            ]
                        },
                         {
                            type: 'rect',
                            css: {
                            top: '347px',
                            left: '439px',
                            color: '#f4f5f7',
                            width: '28px',
                            height: '28px',
                            rotate: 45
                            }
                        },
                        {
                            type: 'text',
                            text: '长按或扫描二维码',
                            css: {
                            fontSize: '18px',
                            color: '#999999',
                            bottom: '22px',
                            left: '360px'
                            }
                        },
                        {
                            type: 'qrcode',
                            content: this.item.access_url,
                            css: {
                            bottom: '50px',
                            right: '26px',
                            color: '#000',
                            width: '180px',
                            height: '180px'
                            }
                        }
                    ],
                },
                active:1,
                imgurl:'',
                wHeight:'620px'
            }
        },
        props:{
            item:Object,
            isImg:Number
        },
        components: {
            VueCanvasPoster,
        },
        created(){
            this.visible = true
            if(this.isImg == 1){
                this.painting = {
                    width: '320px',
                    height: '368px',
                    background: '#fff',
                    views: [
                        {
                            type: 'text',
                            text: this.item.survey_name,
                            css:[
                                {
                                    width: '300px',
                                    top:'40px',
                                    left:'15px',
                                    textAlign:'center',
                                    fontSize:'23px',
                                    lineHeight:'24px',
                                    color: '#999999',
                                }
                            ]
                        },
                        {
                            type: 'text',
                            text: '长按或扫描二维码',
                            css: {
                            fontSize: '14px',
                            color: '#999999',
                            bottom: '42px',
                            left: '105px'
                            }
                        },
                        {
                            type: 'qrcode',
                            content: this.item.access_url,
                            css: {
                            bottom: '80px',
                            right: '95px',
                            color: '#000',
                            width: '130px',
                            height: '130px'
                            }
                        }
                    ],
                }
            }
        },
        methods: {
            handleOk() {
                this.downloadFile(this.item.survey_name + '.png',this.imgurl)
            },
            handleCancel(){this.parent.hideQrcodeModal()},
            success(src) {
                this.imgurl = src
            },
            fail(err) {
                console.log('fail', err)
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
        }
    }
</script>

<style lang="scss">
    .share-image {
        width: 100%;
        height: auto;
    }
    .poster{
        display: none;
    }
    .survey-qrcode{
        .ant-modal-body{
            max-height: 2000px;
        }
    }
    .hdgl-qrcodes{
        text-align: center;
        div{
            width: 250px;
        }
        .canvas{
            position: absolute  !important;
            top:inherit  !important;
        }
    }
</style>