<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场中心</a-breadcrumb-item>
            <a-breadcrumb-item>表单收集</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>表单名称</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.survey_name" placeholder="请输入表单名称" style="width: 160px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                            <a-range-picker
                            style="width: 240px"
                            :ranges="rangesData"
                            v-model='searchDatas.add'
                            allowClear
                            @change="(val,time)=>handleTime(val,time,'add')" />
                    </a-form-item>
                    <a-form-item>
                        <a-select style="width: 200px" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' v-model='searchParams.search.survey_created_by' allowClear showSearch @search="handleSearch" placeholder="请选择添加人" :filterOption="filterOption">
                        <a-select-option v-for="(d, index) of staffList" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item v-if="canIndex">
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                    </a-form>
                </div>
                <div class="buttons">
                    <a-form layout='inline'>
                      <a-form-item v-if="canSave">
                        <a-button type="primary" icon="plus" @click="showEditModal()">新增</a-button>
                      </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="survey_id"
                :columns="columns" :dataSource="list" @change="handleChange">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template v-if="canRead" slot="survey_name" slot-scope="text,record">
                  <a href="javascript:;"  @click="toDetail(record,3)">{{text}}</a>
                </template>
                <template v-if="canRead" slot="survey_question_count" slot-scope="text,record">
                  <a href="javascript:;"  @click="toEditModal(record,2)">{{text}}</a>
                </template>
                <template v-if="canRead" slot="survey_completed_count" slot-scope="text,record">
                  <a href="javascript:;"  @click="toDetail(record,3)">{{text}}</a>
                </template>
                <template slot="action" slot-scope="text,record">
                    <div>
                        <a href="javascript:;" v-if="canRead" key="2" @click="toEditModal(record,2)">表单编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-if="canRead" key="3" @click="toDetail(record,3)">表单数据</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-if="canRead" key="5" @click="toZip(record)">导出数据</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="showCopyModal(record)">复制链接</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-if="canSave" key="6" @click="creatQRcode(record)">二维码</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-if="canSave" key="4" @click="copyDetail(record,4)">复制表单</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" v-if="canDelete" key="7" @click="toDel(record)">删除表单</a>
                    </div>
                    <!-- <a-dropdown>
                        <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                        <a-menu slot="overlay">
                            <a-menu-item v-if="canDelete" key="7" @click="toDel(record)">
                            删除表单
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown> -->
                </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if='isEditModal' :isEdit='isEdit' :survey='survey' />
        <qrcodeModal v-if='isQrcodeModal' :item='modalData' :isImg='isImg' />
        <copyModal v-if="isCopyModal" :item="modalData"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '表单名称', dataIndex: 'survey_name', key: 'survey_name', scopedSlots: { customRender: 'survey_name' } },
  { title: '表单浏览', dataIndex: 'survey_view_count', key: 'survey_view_count',align:"center", scopedSlots: { customRender: 'survey_view_count' },sorter:()=>{}},
  { title: '表单完成', dataIndex: 'survey_completed_count', key: 'survey_completed_count',align:"center", scopedSlots: { customRender: 'survey_completed_count' },sorter:()=>{}},
//   { title: '表单选项', dataIndex: 'survey_question_count', key: 'survey_question_count',align:"center", scopedSlots: { customRender: 'survey_question_count' } },
  { title: '添加人', dataIndex: 'survey_created_by', key: 'survey_created_by'},
  { title: '企业微信', dataIndex: 'survey_qywx_id', key: 'survey_qywx_id'},
  { title: '添加时间', dataIndex: 'survey_created_at', key: 'survey_created_at',sorter:()=>{}},
  { title: '最后收集', dataIndex: 'survey_lastest_submit_at', key: 'survey_lastest_submit_at',sorter:()=>{}},
  { title: '操作', key: 'operation',scopedSlots: { customRender: 'action' },align:"right"}
]
import editModal from './editModal'
import qrcodeModal from './qrcodeModal'
import tableMixins from '@/common/mixins/table'
import authority from '@/common/mixins/authority'
import ranges from "@/common/mixins/ranges"
import copyModal from '@/views/market/weipages/zxbb/copyModal'
    export default {
        name:'survey',
        mixins: [ tableMixins , authority, ranges ],
        components:{editModal,qrcodeModal,copyModal},
        data() {
            return {
                loading: false,
                columns,
                survey:{},
                isImg:1,
                staffList:[],
                searchDatas:{},
                isEditModal:false,
                isCopyModal:false,
                isQrcodeModal:false,
                authType:['market','survey'],
            }
        },
        created () {
            this.getStaff()
        },
        methods: {
            async getList() {
                if(!this.canIndex){
                    this.$message.warning('您暂无权限!!')
                    return false
                }
                this.loading = true
                let res = await this.$store.dispatch('surveyCenterAction',{data:this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getStaff(val){
                let obj = {q:val}
                let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
                this.staffList = res.data
            },
            showEditModal(){
                this.$router.push({path:`/market/surveyEdit/-1/0`})
            },
            hideEditModal(){
                this.isEditModal = false
            },
            toEditModal(val,num){
                if(num == 1){
                    this.isEdit = 1
                    this.survey = val
                    this.isEditModal = true
                }else{ 
                    this.$router.push({path:`/market/surveyEdit/${val.survey_id}/1`})
                }
            },
            copyDetail(val){
                this.$router.push({path:`/market/surveyEdit/${val.survey_id}/2`})
            },
            toExport(){

            },
            toDetail(val){
                this.$router.push({path:`/market/surveyDetail/${val.survey_id}`})
            },
            toDel(val){
                let that = this
                this.$confirm({
                    title: `您确定删除该表单？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('surveyCenterDeleteAction', { data: { survey_id: val.survey_id} })
                        if (res) {
                        that.getList()
                        that.$message.success('操作成功~')
                        resolve(res)
                        }
                    }).catch(error => console.log(error))
                    }
                })
            },
            handleChange(pagination, filters, sorter) {
                console.log(sorter);
                if(sorter.order){
                    if(sorter.order === "ascend"){
                    this.searchParams['sort'] = `${sorter.field}`
                    }else{
                    this.searchParams['sort'] = `-${sorter.field}`
                    }
                }else{
                    this.searchParams['sort'] = ''
                }
                this.getList()
            },
            creatQRcode(val){
                this.modalData = val
                if(val.survey_image){
                    this.isImg = 2
                }else{
                    this.isImg = 1
                }
                this.isQrcodeModal = true
            },
            hideQrcodeModal(){
                this.isQrcodeModal = false
            },
            async toZip(item){
                const hide = this.$message.loading('导出中...', 0)
                await this.$store.dispatch('surveyCenterExportAction',{survey_id:item.survey_id})
                .then(res=>{
                    hide()
                    window.open(res.data.export_path)
                })
                .catch(err=>{
                    hide()
                })
            },
            showCopyModal(item){
                this.modalData = item
                this.isCopyModal = true
            },
            hideCopyModal(){
                this.isCopyModal = false
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>