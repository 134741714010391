<template>
    <a-modal title="分享" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="550px" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
        <a-alert class="copyModal-qrcode-alert" banner message="可将二维码或链接放置在海宣传单、朋友圈、线下等方式发送给客户。" type="success">
            <template slot="icon">
                <a-icon type="bell" />
            </template>
        </a-alert>
        <div style="display:flex;margin-top:10px">
            <div class="copyModal-qrcode">
                <h3>方式一</h3>
                <div>
                    <span>页面二维码</span>
                    <div style="margin-top:10px;" id="qrcode" ref="qrcode"></div>
                </div>
            </div>
            <div class="copyModal-link">
                <h3>方式二</h3>
                <div>
                    <span>页面链接</span>
                    <div class="copyModal-link-box">{{item.access_url}}</div>
                </div>
            </div>
        </div>
        <div style="text-align:right;margin-top: 10px;">
            <a-button @click="handleOk" icon="download" style="margin-right:10px">下载二维码</a-button>
            <a-button type="primary" icon="link" v-clipboard:copy="item.access_url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制链接</a-button>
        </div>
    </a-modal>
</template>

<script>
    import QRCode from 'qrcodejs2'
    export default {
        name:"copyModal",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                confirmLoading: false,
                imgurl:'',
            }
        },
        props: {
            item: Object,
        },
        created () {
            this.visible = true
            this.$nextTick(()=>{
                var canvas = this.$refs.qrcode
                new QRCode(canvas, {
                    text:this.item.access_url,
                    width:200,
                    height:200,
                    colorDark : "#000",   //二维码颜色
                    colorLight : "#ffffff"   //二维码背景色
                })
            })
        },
        methods: {
            handleOk() {
                let myCanvas = this.$refs.qrcode.getElementsByTagName("canvas")
                let imgurl = myCanvas[0].toDataURL("image/png")
                let name = this.item.recruit_name || this.item.survey_name || this.item.payment_title || this.item.meeting_name
                this.downloadFile(`${name}.png`,imgurl)
            },
            handleCancel(){
                this.parent.hideCopyModal()
            },
            onCopy(){
                this.$message.success('已复制到剪切板!')
            },
            onError(){
                this.$message.error('操作失败!')
            },
            downloadFile(fileName, content) {
                let aLink = document.createElement('a');
                let blob = this.base64ToBlob(content); //new Blob([content]);
                let evt = document.createEvent("HTMLEvents");
                evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
                aLink.download = fileName;
                aLink.href = URL.createObjectURL(blob);
                aLink.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));//兼容火狐
            },
            base64ToBlob(code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;

                let uInt8Array = new Uint8Array(rawLength);

                for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {type: contentType});
            },
        },
    }
</script>

<style lang="scss" scoped>
    .copyModal-qrcode{
        width: 200px;
        margin-right: 30px;
    }
    .copyModal-link{
        flex: 1;
        &-box{
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            padding: 10px;
            margin-top: 10px;
            height: 150px;
            width: 272px;
        }
    }
</style>

<style lang="scss">
    .copyModal-qrcode{
        img{
            width: 150px;
        }
    }
    .copyModal-qrcode-alert{
        .ant-alert-icon{
            top: 8.5px;
        }
    }
</style>