<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="62.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
          <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
          <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <l-editor v-else-if="item.type === 'textarea2'" name="content" :data="editor.content"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'survey_image',
    label: '表单头图',
    type: 'upload',
    rules: [{ required: true, message: '请输入通知摘要!' }]
  },
  {
    name: 'survey_name',
    label: '表单名称',
    type: 'input',
    rules: [{ required: true, message: '请输入表单名称!' }],
  },
  {
    name: 'survey_descr',
    label: '表单描述',
    type: 'textarea2',
    rules: []
  },
]
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  provide() {
    return {
      parent: this
    }
  },
  inject: ['parent'],
  props: {
    survey: Object,
    isEdit: Number
  },
  components:{ImageModal},
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增通知',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      content: '',
      editor: {},
      imageUrl: '',
      imagesVisible: false,
    }
  },

  async created() {
    this.visible = true
    console.log(this.survey)
  },
  mounted(){
    if(this.survey){
      this.editor.content = this.survey.survey_descr
      this.imageUrl = this.survey.survey_image
      this.form.setFieldsValue({survey_name:this.survey.survey_name})
    }
  },
  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          params.survey_descr = this.editor.content
          params.survey_image = this.imageUrl
          if(this.isEdit == '0'){
            let res = await this.$store.dispatch('surveyCenterAddAction', { data: params })
            if(res) {
              form.resetFields()
              this.$message.success('操作成功')
              this.$router.push({path:`/market/surveyEdit/${res.data.survey.survey_id}/0`})
            }
          }else{
            params.survey_id = this.survey.survey_id
            let res = await this.$store.dispatch('surveyCenterUpdateAction', { data: params })
            form.resetFields()
            this.$message.success('操作成功')
            this.parent.hideEditModal()
          }
          
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        console.log(image)
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
  }
}
</script>
